import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import 'reflect-metadata'
import { pathes } from '@/routes'
import { DocumentPage } from '@/routes/DocumentPage'
import { MainLayout } from '@/routes/MainLayout'
import { NoMatch } from '@/routes/NoMatch'
import { PrivacyPolicyPage } from '@/routes/PrivacyPolicyPage'
import { SetUpNamePage } from '@/routes/SetUpNamePage'
import { SignInPage } from '@/routes/SignInPage'
import { SignUpPage } from '@/routes/SignUpPage'
import { SuccessPage } from '@/routes/SuccessPage'
import { io } from './api'
import { InitLayout } from './routes/InitLayout'
import { SchemaEditorPage } from './routes/SchemaEditorPage/SchemaEditorPage'
import { SearchPage } from './routes/SearchPage'
import { WhiteboardPage } from './routes/WhiteboardPage'
import { WorkspaceSettings } from './routes/WorkspaceSetttings'
import { useAppDispatch, useSlr } from './store'
import { checkSession } from './store/session/actions'
import { selectSessionStatus } from './store/session/selectors'
import { closeSession } from './store/session/slice'
import { LoadScreen } from './ui/Spinner'
import ReactGA from 'react-ga4'
import get from 'lodash.get'

if (get(window, 'env.GAId')) {
  ReactGA.initialize([
    {
      trackingId: get(window, 'env.GAId') || '',
    },
  ])
}

export const App = () => {
  const dispatch = useAppDispatch()
  const { status } = useSlr(selectSessionStatus)
  // const location = useLocation()

  // useEffect(() => {
  //   if (get(window, 'env.GAId')) {
  //     setTimeout(() => {
  //       ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title })
  //     }, 200)
  //   }
  // }, [location.pathname])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const tokenId = query.get('token')
    const tokenType = query.get('type') ?? ''
    const newClientId = query.get('clientId')

    if (tokenId) {
      dispatch(checkSession({ tokenId, tokenType, newClientId }))
    }
  }, [])

  useEffect(() => {
    if (status === 'success') {
      io.on('hypha_error', (res) => {
        dispatch(closeSession())
        console.error('Server error:', res)
      })

      return () => {
        io.off('hypha_error')
      }
    }

    return () => null
  }, [status])

  if (status === 'pending') {
    return <LoadScreen>Authorization...</LoadScreen>
  }

  return (
    <Routes>
      <Route path={pathes.SIGNIN} element={<SignInPage />} />
      <Route path={pathes.SIGNUP} element={<SignUpPage />} />
      <Route path={pathes.SUCCESS} element={<SuccessPage />} />
      <Route path={pathes.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path={pathes.HOME} element={<InitLayout />}>
        <Route path={pathes.SET_UP_NAME} element={<SetUpNamePage />} />
        <Route path={pathes.WORKSPACE}>
          <Route path={pathes.WHITEBOARD} element={<WhiteboardPage />} />
          <Route element={<MainLayout />}>
            <Route index element={<DocumentPage />} />
            <Route path={pathes.WORKSPACE_SETTINGS} element={<WorkspaceSettings />} />
            <Route path={pathes.SEARCH} element={<SearchPage />} />
            <Route path={pathes.DOCUMENT} element={<DocumentPage />} />
            <Route path={pathes.SCHEMA} element={<SchemaEditorPage />} />
          </Route>
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
}
