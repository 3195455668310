import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PathProvider } from '@/contexts'
import { initAppState } from '@/db/common/sync'
import { pathes } from '@/routes'
import { useSlr } from '@/store'
import { selectInitStatus } from '@/store/account/selectors'
import { selectSessionStatus } from '@/store/session/selectors'
import { LoadScreen } from '@/ui/Spinner'

export const InitLayout = () => {
  const { status } = useSlr(selectSessionStatus)
  const init = useSlr(selectInitStatus)

  useEffect(() => {
    initAppState()
  }, [])

  if (status === 'expired' || status === 'idle') {
    return <Navigate to={pathes.SIGNIN} replace />
  }

  if (init !== 'fulfilled') {
    return <LoadScreen showProgress>Syncing...</LoadScreen>
  }

  return (
    <PathProvider>
      <Outlet />
    </PathProvider>
  )
}
