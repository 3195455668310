import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import {
  findDocumentIdsDelta,
  findGroupDocumentIds,
  getCachedDocument,
  getCacheGroupBundleUrl,
} from '@/api/docs'
import { queryPermissionsGroupsByIds } from '@/db/permissions/queries'
import { fetchPermissionGroups } from '@/db/permissions/sync'
import { store } from '@/store'
import { setInit, setProgress } from '@/store/account/slice'
import { LS, sequentiallyChunked } from '@/utils'
import { isAdmin } from '@/utils/database'
import { getDocAIContext } from '@/utils/docAIContext'
import { moreData, parallel, sequentially } from '@/utils/promises'
import { db } from '../../db'
import { DataDoc, Doc } from '../../dbTypes'
import { bulkPutRelations } from '../../relations/sync'
import { createHomeDoc } from './createDocsMutation'
import { closeSession } from '@/store/session/slice'

export async function fetchDocs(permmissionsPromise?: Promise<any>) {
  const updatedDocsPromise = fetchUpdatedDocs(true)
  await fetchCachedDocs(permmissionsPromise)

  const updatedDocs = await updatedDocsPromise
  await parallel(updatedDocs, (doc) => putDocument(doc))

  await createHomeDoc()
  setTimeout(() => store.dispatch(setInit('fulfilled')), 300)
}

const deletedIds = new Set<string>()

async function fetchCachedDocs(permmissionsPromise?: Promise<any>) {
  const permissionsAwait = async () => {
    if (!permmissionsPromise) {
      permmissionsPromise = fetchPermissionGroups()
    }
    return permmissionsPromise
  }

  const isoModifiedOn = LS.get('disconnectTime')
  if (isoModifiedOn) {
    return permissionsAwait()
  }

  async function getBundleFile(bundleKey = '', type: 'GROUP' | 'READACCESS') {
    try {
      const fileName = bundleKey.substring(bundleKey.indexOf(type))
      const url = await getCacheGroupBundleUrl(fileName)
      const res = await fetch(url)
      return res
    } catch (error) {
      window.Rollbar.error(error as Error)
      window.Rollbar.error('Error fetching cached docs', { status: res?.status, error, bundleKey })
      closeSession()
      return Promise.resolve(null)
    }
  }

  const responses: (Response | null)[] = []

  const { privateBundleKey, everyoneBundleKey } = db.activeWorkspace
  const res = await getBundleFile(everyoneBundleKey, 'READACCESS')

  if (isAdmin(db.activeAccount)) {
    const res = await getBundleFile(privateBundleKey, 'READACCESS')
    responses.push(res)
  } else {
    await permissionsAwait()

    const groups = await queryPermissionsGroupsByIds(db.activeAccount.permissionGroups)
    if (!groups) return

    await Promise.all(
      groups.map(async ({ bundleKey }) => {
        const res = await getBundleFile(bundleKey, 'GROUP')
        responses.push(res)
        return res
      }),
    )
  }

  responses.push(res)

  await sequentially(responses, async (res) => {
    try {
      if (!res?.ok) return

      const data = await res.json()
      await parallel(data, (doc) => putDocument(doc, true))
    } catch (error) {
      window.Rollbar.error(error as Error)
    }
  })

  deletedIds.clear()
}

export async function fetchUpdatedDocs(initLoading = false) {
  let isoModifiedOn = LS.get('disconnectTime')
  const docIds: Record<string, 'sent' | 'received' | 'failed'> = {}

  const workspaceDate = db.activeWorkspace?._modifiedOn
  if (!isoModifiedOn && workspaceDate) {
    isoModifiedOn = new Date(new Date(workspaceDate).getTime() - 2 * 60 * 60 * 1000).toISOString()
  }

  const data = await findDocumentIdsDelta(isoModifiedOn, true)
  const { everyoneDocs, privateDocs, permissionsDocs } = data

  let loaded = 0
  const total = everyoneDocs.length + privateDocs.length + permissionsDocs.length

  const updateProgress = throttle(() => {
    store.dispatch(setProgress({ loaded, total }))
  }, 500)

  if (initLoading) {
    store.dispatch(setInit('pending'))
  }

  const deletedDocs = new Set<string>()
  const docs: DataDoc[] = []

  await parallel(
    Object.values(data),
    (baseDocs) =>
      sequentiallyChunked(
        baseDocs,
        async ({ _id }) => {
          docIds[_id] = 'sent'
          let doc = await getCachedDocument(_id)
          ++loaded
          updateProgress()
          docIds[_id] = doc ? 'received' : 'failed'

          if (!doc || deletedDocs.has(_id)) {
            deletedDocs.add(_id)
            doc = { deletedId: _id }
          }

          docs.push(doc)
        },
        1000,
      ),
    { filtered: false },
  )

  updateProgress.cancel()
  store.dispatch(setProgress({ loaded: total, total }))
  store.dispatch(setInit('fulfilled'))
  return docs
}

export function fetchGroupsDocs(groupIds: string[]) {
  return parallel(
    groupIds,
    async (groupId) => {
      const data = await findGroupDocumentIds(groupId)
      await sequentiallyChunked(
        data,
        async ({ _id }) => {
          const doc = await getCachedDocument(_id)
          await putDocument(doc || { deletedId: _id })
        },
        1000,
      )
    },
    { withResult: false, filtered: false },
  )
}

function isDeletedDoc(doc: DataDoc | Doc): doc is { deletedId: string } {
  return 'deletedId' in doc
}

export async function putDocument(item?: DataDoc | Doc, initial = false) {
  if (!item) return

  if (isDeletedDoc(item)) {
    deletedIds.add(item.deletedId)
    return db.ws.transaction('rw', db.ws.docs, () => db.ws.docs.delete(item.deletedId))
  }

  if (item.isDeleted || deletedIds.has(item._id)) {
    return db.ws.transaction('rw', db.ws.docs, () => db.ws.docs.delete(item._id))
  }

  const { relations, permissions, ...doc } = item
  const { secondaryId, userId } = doc.fields

  doc.aiContext = await getDocAIContext(doc)
  doc.secondaryId = secondaryId as string
  doc.userId = userId as string

  return db.ws.transaction('rw', db.ws.docs, db.ws.permissions, db.ws.relations, async () => {
    await db.ws.docs.put(doc)

    if (permissions) {
      await db.ws.permissions.put({ _id: doc._id, permissions })
    }

    if (relations) {
      await bulkPutRelations(relations, initial)
    }
  })
}

export const fetchDocsDebounced = debounce(() => fetchUpdatedDocs(), 2000)

export async function fetchAllDocs() {
  const isoDate = LS.get('disconnectTime')

  return moreData<DataDoc>({
    initEvent: isoDate ? 'findDocumentsByDate' : 'findDocuments',
    moreEvent: isoDate ? 'moreFindDocumentsByDate' : 'moreFindDocuments',
    payload: isoDate ? { isoDate } : { _excludeDeleted: true },
    cb: (data) => parallel(data.items, (doc) => putDocument(doc)),
  })
}
