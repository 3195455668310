import {
  useState,
  createElement,
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react'
import Masonry from 'react-layout-masonry'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import clsx from 'clsx'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc, FieldValueType } from '@/db'
import { useGroup, useSort } from '@/schema/hooks'
import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { CardFooterElements, CardHeaderElements } from '@/schema/cards/components/CardElements'
import { ListEmptyState } from '@/schema/cards/ListEmptyState.tsx'
import { AttachmentCard } from '@/schema/cards/variants/AttachmentCard.tsx'
import { CarouselCard } from '@/schema/cards/variants/CarouselCard.tsx'
import { ColorfulCard } from '@/schema/cards/variants/ColorfulCard.tsx'
import { DefaultCard } from '@/schema/cards/variants/DefaultCard.tsx'
import { FullDescriptionCard } from '@/schema/cards/variants/FullDescriptionCard.tsx'
import { GoalCard } from '@/schema/cards/variants/GoalCard.tsx'
import { HorizontalCard } from '@/schema/cards/variants/HorizontalCard.tsx'
import { ImpactCard } from '@/schema/cards/variants/ImpactCard.tsx'
import { ListItem2RowsCard } from '@/schema/cards/variants/ListItem2RowsCard.tsx'
import { ListItemCard } from '@/schema/cards/variants/ListItemCard.tsx'
import { NoImageCard } from '@/schema/cards/variants/NoImageCard.tsx'
import { OneFourthCard } from '@/schema/cards/variants/OneFourthCard.tsx'
import { PersonaCard } from '@/schema/cards/variants/PersonaCard.tsx'
import { PrimaryCard } from '@/schema/cards/variants/PrimaryCard.tsx'
import { SelectCard } from '@/schema/cards/variants/SelectCard.tsx'
import { StatusCard } from '@/schema/cards/variants/StatusCard.tsx'
import { TeamMemberCard } from '@/schema/cards/variants/TeamMemberCard.tsx'
import { TinyPersonaCard } from '@/schema/cards/variants/TinyPersonaCard.tsx'
import { getClassName, getGapClassNames } from '@/utils/docsList.ts'
import { scm, useSchemaState } from '@/contexts/schema'
import './carousel.css'
import { FAQCard } from './variants/FAQCard'
import { FAQVerticalCard } from './variants/FAQVertical'
import { FlipCard } from './variants/FlipCard'
import { HorizontalPersonaCard } from './variants/HorizontalPersonaCard'
import { HorizontalSmallCard } from './variants/HorizontalSmall'
import { IkeaCard } from './variants/IkeaCard'
import { SimpleCard } from './variants/SimpleCard'
import { WithIconCard } from './variants/WithIconCard'
import { CategoryCard } from './variants/CategoryCard'
import { KeyFactCard } from './variants/KeyFactCard'
import { CardWithImage } from '@/schema/cards/variants/CardWithImage.tsx'
import { Button, Icon } from '@/ui'
import { VerticalCarouselCard } from '@/schema/cards/variants/VerticalCarouselCard.tsx'
import { CardWithImageVertical } from '@/schema/cards/variants/CardWithImageVertical.tsx'
import { MiniCard } from '@/schema/cards/variants/MiniCard.tsx'
import { ButtonCard } from '@/schema/cards/variants/ButtonCard.tsx'
import { useFilterDocs } from '@/features/filter'
import { ImpactStoryCard } from '@/schema/cards/variants/ImpactStoryCard.tsx'
import { IkeaDefaultCard } from '@/schema/cards/variants/IkeaDefaultCard.tsx'
import { GroupLabelCard } from '@/schema/cards/GroupLabelCard.tsx'
import { AreaRenderer } from '@/schema'
import { CardWrapper } from './CardWrapper'
import { FullImageCard } from './variants/FullImageCard'
import { setNewDoc } from '@/store/docs'
import { useAppDispatch } from '@/store'
import { useNavigate } from 'react-router-dom'
import { InfoCard } from './variants/InfoCard'
import { InfoCard2 } from './variants/InfoCard_2'
import { TemplateCard } from '@/schema/cards/variants/TemplateCard.tsx'
import { BannerCard } from '@/schema/cards/variants/BannerCard.tsx'
import { CardWithGrid } from '@/schema/cards/variants/CardWithGrid.tsx'
import { usePath } from '@/hooks'
import { SmallCard } from './variants/SmallCard'
import { TileCard } from './variants/TileCard.tsx'
import { groupArr } from '@/utils/docsSort.ts'
import get from 'lodash.get'
import { objToLabelArr } from '@/schema/hooks/useGroup.ts'
import css from '@/schema/cards/card.module.css'
import { ReferenceCard } from '@/schema/cards/variants/ReferenceCard.tsx'
// import { StatusFieldType } from '@common/interfaces/fields/status-field.interface.ts'

const GRID_CARD_TYPE_COMPONENTS: Record<string, FunctionComponent<CardProps>> = {
  default: DefaultCard,
  horizontal: HorizontalCard,
  horizontalPersona: HorizontalPersonaCard,
  horizontalSmall: HorizontalSmallCard,
  persona: PersonaCard,
  colorful: ColorfulCard,
  oneFourth: OneFourthCard,
  listItem: ListItemCard,
  listItem2Rows: ListItem2RowsCard,
  noImage: NoImageCard,
  select: SelectCard,
  fullDescription: FullDescriptionCard,
  teamMember: TeamMemberCard,
  tinyPersona: TinyPersonaCard,
  flipCard: FlipCard,
  simpleCard: SimpleCard,
  goalCard: GoalCard,
  carousel: CarouselCard,
  status: StatusCard,
  impact: ImpactCard,
  attachment: AttachmentCard,
  primary: PrimaryCard,
  faq: FAQCard,
  ikea: IkeaCard,
  faqVertical: FAQVerticalCard,
  withIcon: WithIconCard,
  categoryCard: CategoryCard,
  keyFact: KeyFactCard,
  withImage: CardWithImage,
  verticalCarousel: VerticalCarouselCard,
  withImageVertical: CardWithImageVertical,
  miniCard: MiniCard,
  buttonCard: ButtonCard,
  impactStoryCard: ImpactStoryCard,
  ikeaDefault: IkeaDefaultCard,
  '001.FullImageCard': FullImageCard,
  '002.InfoCard': InfoCard,
  '003.InfoCard': InfoCard2,
  fullImage: FullImageCard,
  '004.TemplateCard': TemplateCard,
  '005.BannerCard': BannerCard,
  gridCard: CardWithGrid,
  '006.A.SmallCard': SmallCard,
  tileCard: TileCard,
  referenceCard: ReferenceCard,
}

const GRID_CARD_TYPE_HEIGHTS: Record<string, number> = {
  default: 168,
  horizontal: 162,
  persona: 162,
  colorful: 200,
  oneFourth: 110,
  listItem: 48,
  listItem2Rows: 80,
  noImage: 70,
  select: 26,
  fullDescription: 85,
  teamMember: 75,
  tinyPersona: 32,
}

export const getCardComponent = (type = 'default') => {
  if (GRID_CARD_TYPE_COMPONENTS[type]) {
    return GRID_CARD_TYPE_COMPONENTS[type]
  }

  return DefaultCard
}

export const List = ({
  docs,
  setCurrentDoc,
  type,
  parentDoc,
}: {
  docs: Doc[]
  setCurrentDoc: (doc: Doc) => void
  type?: 'children' | 'multilink' | 'query'
  parentDoc?: Doc
}) => {
  const { doc } = useDocContext()
  const { field, isEditable } = useFieldContext<ChildrenFieldType, Doc[]>()
  const docsSchema = useSchemaState((state) => state.docTypes)

  const { columns, gapX, gapY, card, classes, columnFlow, arrayLength } = field.layout || {
    columns: 0,
    gapX: 7,
    gapY: 7,
    card: { type: 'default' },
  }

  const enableFullImageModal = field.layout?.card?.enableFullImageModal

  // const childrenNiceNames = useMemo(
  //   () => field.allowed?.map((type) => camelToCapitalizeWords(type)).join(', ') ?? 'documents',
  //   [field],
  // )

  const { setModal } = usePath()
  const filteredDocs = useFilterDocs(docs)
  const sortedDocs = useSort(filteredDocs, field.sort)
  const { groupsArr } = useGroup(sortedDocs, field.group)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const openModal = (item: Doc, slug: string) => {
    if (isEditable) {
      navigate({
        pathname: location.pathname,
        search: `?row=${slug}`,
      })
      dispatch(setNewDoc(item))
    }
  }

  const onCardDelete = useCallback((event: MouseEvent<HTMLButtonElement>, docEntry: Doc) => {
    event.preventDefault()
    event.stopPropagation()

    setCurrentDoc(docEntry)
  }, [])

  const createCardElement = useCallback(
    (item: Doc, index?: number) => {
      const cardElement = createElement(
        getCardComponent(card?.type || 'default'),
        {
          doc: item,
          docSchema: docsSchema[item._type],
          isEditMode: type !== 'query' && isEditable,
          onDelete: (e) => onCardDelete(e, item),
          author: card?.elements?.author,
          date: card?.elements?.date,
          showQuote: card?.elements?.showQuote,
          parentDoc,
          index,
          headerElements: (
            <CardHeaderElements
              docItem={item}
              onDelete={(e) => onCardDelete(e, item)}
              isEditMode={isEditable}
              date={card?.elements?.date}
              docSchema={docsSchema[item._type]}
            />
          ),
          setImage: enableFullImageModal
            ? (e: MouseEvent, image: FieldValueType) => {
                e.preventDefault()
                setModal({
                  type: 'image',
                  image,
                })
              }
            : undefined,
        },
        <CardFooterElements
          docSchema={docsSchema[item._type]}
          docItem={item}
          isEditMode={isEditable}
          date={card?.elements?.date}
          styles={card?.footerElementsStyles}
        />,
      )

      const lastElementStyles =
        field.lastElementStyles && sortedDocs.length === index ? field.lastElementStyles : undefined

      return (
        <CardWrapper
          makeInactive={card?.makeInactive}
          doc={item}
          isEditable={isEditable}
          lastElementStyles={lastElementStyles}
        >
          {cardElement}
        </CardWrapper>
      )
    },
    [card, type, isEditable, onCardDelete, docsSchema],
  )

  const [activeTabDoc, setActiveTabDoc] = useState<Doc>(sortedDocs?.[0])

  // Need to update doc in tab if values were changed
  useEffect(() => {
    if (activeTabDoc && field.viewComponent === 'tabs') {
      const currentDoc = sortedDocs.find((item) => item._id === activeTabDoc._id)
      if (currentDoc) setActiveTabDoc(currentDoc)
    }
  }, [sortedDocs])

  const createDocument = useCallback(async () => {
    const dSchema = scm.getDocSchema(field.allowed?.[0])

    if (dSchema) {
      const payload: Partial<Doc> = {
        _type: field.allowed?.[0],
        _parentId: doc._id,
        _ancestors: [...doc._ancestors, doc._id],
      }

      if (dSchema?.defaultFieldValues) {
        payload.fields = dSchema.defaultFieldValues
      }

      navigate({
        pathname: location.pathname,
        search: `?row=newDocument`,
      })
      dispatch(setNewDoc({ ...payload, parentDoc: doc, isNew: true }))
    }
  }, [doc, field])

  if (!sortedDocs.length) {
    return <ListEmptyState height={GRID_CARD_TYPE_HEIGHTS[card?.type || 'default'] || 168} />
  }

  if (groupsArr) {
    return (
      <div className={clsx(columnFlow ? 'flex overflow-x-auto' : 'grid gap-7')}>
        {groupsArr
          .filter((group) => group.docs?.length)
          .map((group) => (
            <div
              key={group.label}
              className={clsx('rounded-md mr-4 py-4', columnFlow && 'bg-neutral100')}
            >
              {group.label &&
                (field?.group?.byLinkedDocument ? (
                  <GroupLabelCard id={group.label} />
                ) : (
                  <p className="text-regular font-bold mb-4 mx-2">
                    {group.label}{' '}
                    {field?.group?.showCount && (
                      <span className={clsx('ml-2', columnFlow && 'text-neutral700 ')}>
                        {group.docs.length}
                      </span>
                    )}
                  </p>
                ))}

              <div
                className={clsx(
                  getClassName(columns),
                  getGapClassNames(gapX, gapY),
                  'mx-2',
                  classes,
                )}
              >
                {group.docs.map((entity) => createCardElement(entity))}
              </div>
            </div>
          ))}
      </div>
    )
  }

  if (field.isCarousel || field.viewComponent === 'carousel') {
    const chunkSize = columns ?? 5

    const result = docs.reduce((resultArray: Array<Array<Doc>>, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    result.length = arrayLength || result.length
    const { axis, showIndicators, showArrows } = field?.verticalCarousel ?? {
      axis: 'horizontal',
      showIndicators: true,
      showArrows: false,
    }

    return (
      <div className="list-carousel overflow-hidden">
        <ReactCarousel
          swipeable
          showArrows={showArrows}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          axis={axis}
          showIndicators={showIndicators}
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext &&
            axis === 'vertical' && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="w-7 h-7 rounded-full flex items-center justify-center absolute right-4 btn-next"
              >
                <Icon name="chevron-down" color="#000" />
              </button>
            )
          }
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev &&
            axis === 'vertical' && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="w-7 h-7 rounded-full flex items-center justify-center absolute right-4 btn-prev"
              >
                <Icon name="chevron-up" color="#000" />
              </button>
            )
          }
        >
          {result.map((chunk) => (
            <div
              key={chunk?.[0]?._id}
              className={clsx(
                'grid',
                getClassName(columns),
                getGapClassNames(gapX, gapY),
                classes,
                {
                  'grid-cols-1': columns === 1,
                },
              )}
            >
              {chunk.map((item) => createCardElement(item))}
            </div>
          ))}
        </ReactCarousel>
      </div>
    )
  }

  if (field.viewComponent === 'masonry') {
    return (
      <>
        <div>
          <Masonry columns={columns || 3} gap={20}>
            {sortedDocs.map((item) => createCardElement(item))}
          </Masonry>
        </div>
      </>
    )
  }

  if (field.viewComponent === 'tabs') {
    const dSchema = scm.getDocSchema(activeTabDoc?._type)
    const { fieldName = '' } = field.layout?.card?.query ?? {}

    // const stageField: StatusFieldType | any = dSchema?.fields.find((item) => item.name === 'stage')
    // let options = []
    // if (stageField) {
    //   options = stageField.options
    // }

    const entries = groupArr(sortedDocs, (docItem) => {
      let groupKey = docItem.fields[fieldName] as string
      if (fieldName.includes('.')) {
        groupKey = get(docItem.fields, fieldName) as string
      }
      if (typeof groupKey === 'string' && groupKey) {
        return groupKey
      }
      return 'others'
    })

    // options.forEach((option: any) => {
    //   if (!entries[option.value]) {
    //     entries[option.value] = []
    //   }
    // })

    const groupedDocs = objToLabelArr(entries) ?? []

    return (
      <div>
        {isEditable && (
          <Button className="mb-2" onClick={createDocument}>
            Add new
          </Button>
        )}

        <div
          className={clsx('grid grid-flow-col grid-cols-2 gap-3 border-b-[12px] border-[#FFCF02]', {
            '!auto-cols-fr': groupedDocs?.length > 2,
          })}
        >
          {groupedDocs
            ?.sort((a, b) => b.label.length - a.label.length)
            .map((entry) => (
              <div key={entry.label} className="">
                <div
                  className={clsx(
                    'bg-neutral400 relative text-[14px] capitalize flex items-center justify-center h-12 mb-4 pl-8 pr-1',
                    entry.docs.length ? css.arrow : css.arrowGrey,
                    { '!bg-[#FFCF02]': entry.docs.length },
                  )}
                  style={
                    entry.docs?.[0] && {
                      backgroundColor: entry.docs[0]?.fields?.secondaryColor as string,
                    }
                  }
                >
                  {entry.label}
                </div>
                <div className="flex flex-wrap gap-3">
                  {entry.docs.map((item) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      key={item._id}
                      className={clsx(
                        'relative bg-neutral400 text-center text-[12px] flex items-center justify-center w-[100px] h-[95px] p-2 border-t-[10px] border-t-[#FFCF02] border-b-[12px] border-b-white cursor-pointer',
                        { '!bg-[#FFCF02] !border-b-[#FFCF02]': item._id === activeTabDoc?._id },
                      )}
                      onClick={() => setActiveTabDoc(item)}
                      style={{
                        borderTopColor: item?.fields?.secondaryColor as string,
                        backgroundColor: item?.fields?.color as string,
                      }}
                    >
                      {item.title}
                      {isEditable && (
                        <div className="absolute top-2 right-2 flex items-center gap-1">
                          <Button
                            onClick={() => openModal(item, item._slug)}
                            icon="file-pen-line"
                          />

                          <Button onClick={(e) => onCardDelete(e, item)} icon="trash" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>

        {!isEditable && activeTabDoc && (
          <div className="grid gap-5 pt-5">
            <AreaRenderer fields={dSchema?.fields} doc={activeTabDoc} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={clsx(getClassName(columns), getGapClassNames(gapX, gapY), classes)}>
      {sortedDocs.map((item, index) => createCardElement(item, index + 1))}
    </div>
  )
}
