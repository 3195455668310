import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { Workspace } from '@/db'
import { LS } from '@/utils'
import { deleteAllDatabases } from '@/utils/database'
import { reload } from '@/utils/localStorage'
import { checkSession } from './actions'

if (LS.isNewVersion()) {
  deleteAllDatabases()
  LS.clear()
  reload(true)
}

export type SessionStatus = 'idle' | 'pending' | 'success' | 'expired'
type ConnectionStatus = 'pending' | 'success'

interface SessionState {
  status: SessionStatus
  connection: ConnectionStatus
  userId?: string
  clientId?: string
  workspaceId?: string
}

function getInitialStatus(): SessionStatus {
  const query = new URLSearchParams(window.location.search)

  if (query.get('token')) return 'pending'

  const expDate = Cookies.get('exp')
  const token = Cookies.get('token')

  if (!token || !expDate) {
    window.Rollbar.error('no token')
    return 'idle'
  }

  const date = new Date(expDate)
  if (Date.now() >= +date) {
    Cookies.remove('token')
    Cookies.remove('exp')
    window.Rollbar.error('Token expired')
    return 'expired'
  }
  return 'success'
}

const initialState: SessionState = {
  status: getInitialStatus(),
  connection: 'pending',
}

export function clearSession() {
  LS.clear()
  deleteAllDatabases()
  reload(false)
}

function closeSessionGlobal() {
  Cookies.remove('token')
  Cookies.remove('exp')
  clearSession()

  return initialState
}

window.closeSession = closeSessionGlobal
window.clearSession = clearSession

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<SessionStatus>) {
      state.status = action.payload
    },
    setConnection(state, action: PayloadAction<ConnectionStatus>) {
      state.connection = action.payload
    },
    switchWorkspace(state, action: PayloadAction<Workspace>) {
      state.workspaceId = action.payload._id
      state.clientId = action.payload._partId
    },
    closeSession: closeSessionGlobal,
  },
  extraReducers(builder) {
    builder.addCase(checkSession.fulfilled, (state, action) => {
      const { status, userId, newClientId } = action.payload
      if (status) {
        state.status = 'success'
        if (userId !== state.userId) {
          state.userId = userId
        }
        if (newClientId) {
          state.clientId = newClientId
        }
      } else {
        state.status = 'expired'
      }
    })
    builder.addCase('persist/REHYDRATE', (_, action: any) => ({
      ...action.payload?.session,
      connection: 'pending',
      status: getInitialStatus(),
    }))
  },
})

export const { setStatus, setConnection, switchWorkspace, closeSession } = sessionSlice.actions

export const { reducer } = sessionSlice
